<template>
  <section class="py-4 px-5">
    <b-form-row v-if="restrictUsersToMultipleCity">
      <b-col>
        <b-alert show variant="danger">{{
          DISPLAY_MESSAGES.MULTIPLE_CITIES_USER_DETAILS_ERROR
        }}</b-alert>
      </b-col>
    </b-form-row>
    <h3 class="page-header">Users</h3>
    <b-row align-h="between" class="my-4">
      <b-col lg="7" md="8" sm="9" cols="12">
        <div class="floating-input-field">
          <b-form-input
            id="form-input-search-users"
            class="mb-2 mb-sm-1"
            placeholder=" "
            v-model="filter"
            type="text"
          ></b-form-input>
          <label for="form-input-search-users"
            >Search by name, email, telephone or address
            <BIconSearch class="input-search-icon"
          /></label>
        </div>
      </b-col>
      <b-col lg="5" md="4" sm="3" cols="12" class="mb-2 mb-sm-1 text-right">
        <b-dropdown
          class="action-dropdown"
          no-caret
          variant="outline-secondary"
          :disabled="!usersSelected.length || restrictUsersToMultipleCity"
          right
        >
          <template #button-content>
            Group actions
            <BIconChevronDown class="pt-1" variant="dark" scale="0.65" />
          </template>
          <template v-for="(action, index) in userGroupActionsOptions">
            <b-dropdown-item
              v-if="action.value != 3"
              href="javascript:void(0)"
              :key="index"
              @click="userAction(action, true, '')"
              >{{ action.text }}</b-dropdown-item
            >
          </template>
        </b-dropdown>
      </b-col>
    </b-row>
    <!-- Users table element -->
    <b-table
      :items="users"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      show-empty
      @filtered="onFiltered"
      @row-dblclicked="onUserRowDblclicked"
      hover
      class="table-transition"
      id="users-table"
      primary-key="id"
      :tbody-transition-props="transProps"
      :busy="usersLoading"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong> Loading...</strong>
        </div>
      </template>
      <template #cell(checkbox)="row">
        <b-form-checkbox
          :disabled="restrictUsersToMultipleCity"
          :checked="row.item.checked"
          @change="selectUser($event, row.item)"
        >
        </b-form-checkbox>
      </template>
      <template #cell(fullName)="row">
        <router-link
          class="theme-font-bold"
          :class="{'pe-none': restrictUsersToMultipleCity}"
          :to="'/users/userdetails/' + row.item.userId"
          v-if="row.item.fullName"
        >
          {{ row.item.fullName }}
        </router-link>
      </template>

      <template #cell(actions)="row">
        <b-dropdown
          class="action-dropdown"
          no-caret
          variant="outline-secondary"
          :disabled="restrictUsersToMultipleCity"
          right
        >
          <template #button-content>
            Actions
            <BIconChevronDown class="pt-1" variant="dark" scale="0.65" />
          </template>
          <b-dropdown-item
            href="javascript:void(0)"
            @click="userAction(action, false, row.item)"
            v-for="(action, index) in userGroupActionsOptions"
            :disabled="
              (row.item.isActive && action.value == 1) ||
              (!row.item.isActive && action.value == 0)
            "
            :key="index"
            >{{ action.text }}</b-dropdown-item
          >
        </b-dropdown>
      </template>
    </b-table>
    <!-- Table options-->
    <b-form-row>
      <b-col sm="6" md="6" lg="3" cols="12" class="my-1">
        <b-form-group
          label="Per page"
          label-for="per-page-select"
          label-cols-sm="5"
          label-cols-md="6"
          label-cols-lg="4"
          label-cols-xl="3"
          label-cols="3"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col lg="6" class="d-none d-lg-block"></b-col>
      <b-col sm="6" md="6" lg="3" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-form-row>
    <ConfirmationModal
      :showModal="activateUserConfirmModal"
      :title="`CONFIRM`"
      :message="
        !selectedAction
          ? selectedGroup
            ? `DEACTIVATE_USERS`
            : `DEACTIVATE_USER`
          : selectedGroup
          ? `ACTIVATE_USERS`
          : `ACTIVATE_USER`
      "
      @onConfirm="activateOrDeactivateUser"
      @closeConfirmModal="activateUserConfirmModal = false"
    />
    <ConfirmationModal
      :showModal="resetPasswordConfirmModal"
      :title="`CONFIRM`"
      :message="selectedGroup ? `RESET_PASSWORD_USERS` : `RESET_PASSWORD_USER`"
      @onConfirm="resetPassword"
      @closeConfirmModal="resetPasswordConfirmModal = false"
    />
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { UserGroupActionsOptions, DISPLAY_MESSAGES } from '../../utilities/constants'
import { BIconSearch, BIconChevronDown } from 'bootstrap-vue'

export default {
  name: 'Users',
  computed: {
    restrictUsersToMultipleCity () {
      return (
        this.selectedTenant.length > 1 ||
        this.selectedTenant.some((tenant) => tenant.tenantID === 0)
      )
    },
    ...mapState({
      users: (state) => state.users.users,
      userRole: (state) => state.common.userRole,
      selectedTenant: (state) => state.common.selectedTenant
    })
  },
  components: {
    BIconSearch,
    BIconChevronDown,
    ConfirmationModal: () => import('../common/ConfirmationModal.vue')
  },
  data () {
    return {
      userGroupActionsOptions: UserGroupActionsOptions,
      transProps: {
        // Transition name
        name: 'flip-list'
      },
      usersLoading: false,
      usersSelected: [],
      selectedGroup: null,
      selectedAction: null,
      activateUserConfirmModal: false,
      resetPasswordConfirmModal: false,
      fields: [
        { key: 'checkbox', label: '' },
        {
          key: 'fullName',
          label: 'Users',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'fullAddress',
          label: 'Primary address',
          sortable: true
        },
        {
          key: 'userEmail',
          label: 'Email',
          sortable: true
        },
        { key: 'phoneNumber', label: 'Tel number', sortable: true },
        {
          key: 'actions',
          label: 'Action',
          tdClass: 'text-right',
          thClass: 'text-center'
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, 25],
      sortBy: 'fullName',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: ['fullName', 'fullAddress', 'userEmail', 'phoneNumber'],
      DISPLAY_MESSAGES: DISPLAY_MESSAGES
    }
  },
  created () {
    this.getUsers()
  },
  mounted () {
    if (this.userRole === 'SysAdmin') {
      this.fields.splice(3, 0, {
        key: 'cityName',
        label: 'City name',
        sortable: true,
        sortDirection: 'desc'
      })
    }
  },
  methods: {
    getUsers () {
      if (this.selectedTenant.length) {
        this.usersLoading = true
        this.$store
          .dispatch('users/getUsers')
          .then((response) => {
            this.usersLoading = false
            if (response.data) {
              this.totalRows = response.data ? response.data.length : 0
            }
          })
          .catch(() => {
            this.$store.commit('common/setCustomToastData', {
              message: false,
              key: 'USER_LIST_ERROR',
              type: 'danger'
            })
            this.usersLoading = false
            this.totalRows = 0
          })
      }
    },
    onUserRowDblclicked (item) {
      if (!this.restrictUsersToMultipleCity) {
        this.$router.push(`/users/userdetails/${item.userId}`).catch(() => {})
      }
    },
    selectUser (event, selectedUserInfo) {
      this.$set(selectedUserInfo, 'checked', event)
      if (event) {
        this.usersSelected.push(selectedUserInfo)
      } else {
        const index = this.usersSelected.findIndex(
          (user) => (user.userId = selectedUserInfo.userId)
        )
        if (index > -1) {
          this.usersSelected.splice(index, 1)
        }
      }
    },
    activateOrDeactivateUser () {
      const status = this.selectedAction !== 0
      const postObj = []
      if (this.selectedGroup) {
        this.usersSelected.forEach((user) => {
          postObj.push({ userID: user.userId, isActive: status })
        })
      } else {
        postObj.push({
          userID: this.clickedUser.userId,
          isActive: status
        })
      }
      this.usersSelected = []
      this.$store
        .dispatch('users/activateOrDeactivateUsers', postObj)
        .then((response) => {
          this.activateUserConfirmModal = false
          if (response.data) {
            this.getUsers()
            this.$store.commit('common/setCustomToastData', {
              message: false,
              key: status ? 'ACTIVATE_USER' : 'DEACTIVATE_USER',
              type: 'success'
            })
          } else {
            this.$store.commit('common/setCustomToastData', {
              message: false,
              key: 'FAILED',
              type: 'danger'
            })
          }
        })
        .catch(() => {
          this.activateUserConfirmModal = false
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'FAILED',
            type: 'danger'
          })
        })
    },
    resetPassword () {
      const postObj = []
      if (this.selectedGroup) {
        this.usersSelected.forEach((user) => {
          postObj.push({ tenantId: user.tenantId, email: user.userEmail })
          this.$set(
            this.users[
              this.users.findIndex((data) => data.userId === user.userId)
            ],
            'checked',
            false
          )
        })
      } else {
        postObj.push({
          tenantId: this.clickedUser.tenantId,
          email: this.clickedUser.userEmail
        })
        this.$set(
          this.users[
            this.users.findIndex(
              (data) => data.userId === this.clickedUser.userId
            )
          ],
          'checked',
          false
        )
      }
      this.usersSelected = []
      this.$store
        .dispatch('users/resetPassword', postObj)
        .then((response) => {
          this.resetPasswordConfirmModal = false
          if (response.data) {
            this.$store.commit('common/setCustomToastData', {
              message: false,
              key: 'RESET_PASSWORD_SUCCESS',
              type: 'success'
            })
          } else {
            this.$store.commit('common/setCustomToastData', {
              message: false,
              key: 'RESET_PASSWORD_ERROR',
              type: 'danger'
            })
          }
        })
        .catch(() => {
          this.resetPasswordConfirmModal = false
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'RESET_PASSWORD_ERROR',
            type: 'danger'
          })
        })
    },
    userAction (action, isGroup, user) {
      this.selectedAction = action.value
      this.selectedGroup = isGroup
      this.clickedUser = user
      if (action.value === 3) {
        this.$router
          .push(`/users/userdetails/${user.userId}`)
          .catch(() => {})
      } else if (action.value === 0 || action.value === 1) {
        this.activateUserConfirmModal = true
      } else if (action.value === 2) {
        this.resetPasswordConfirmModal = true
      }
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  },
  watch: {
    selectedTenant (newValue, oldValue) {
      this.getUsers()
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
